import axios from "axios";
import { getDataProps } from "../interface/sidebar";

export const predictDataApi = {
  getPredictSpeed: async (linkId: string) => {
    const response = await axios.get(`traffic/link/predict/speed/${linkId}`);
    // console.log(
    //   "✅ predictDataApi getPredictSpeed response ",
    //   response.data.data.items,
    // );
    return response.data?.data?.items;
  },
  getPastPredictSpeed: async (param: getDataProps) => {
    const { linkId, startDate, endDate } = param;
    console.log("✅ predictDataApi getPastPredictSpeed param ", param);
    const response = await axios.get(
      `traffic/link/predict/avg/speed/${linkId}`,
      {
        params: {
          startDate,
          endDate,
        },
      },
    );
    // console.log(
    //   "✅ predictDataApi getPastPredictSpeed response ",
    //   response.data.data.items,
    // );
    return response.data?.data?.items;
  },
  getPastPredictAllDaySpeed: async (linkId: string, date: string) => {
    console.log(
      "✅ predictDataApi getPastPredictAllDaySpeed param",
      linkId,
      date,
    );
    const response = await axios.get(
      `traffic/link/predict/speeds/${linkId}/${date}`,
    );
    console.log(
      "✅ predictDataApi getPastPredictAllDaySpeed response ",
      response.data.data.items,
    );
    return response.data?.data?.items;
  },
};
