import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ChartData, ChartDataValue } from "../../../../interface/chart";
import { useRecoilValue } from "recoil";
import { searchParamState } from "../../../../atoms";
import Line from "../../../chart/Line";
import { predictDataApi } from "../../../../api/predictDataApi";

const PredictChartSection = () => {
  const searchParam = useRecoilValue(searchParamState);
  const [chartData, setChartData] = useState<ChartData>({
    name: searchParam.roadName,
    data: [],
  });

  useEffect(() => {
    console.log("💕 PredictChart chartData", chartData);
  }, [chartData]);

  const { data: getPredictSpeedData, isLoading: getPredictSpeedLoading } =
    useQuery(
      ["getPredictSpeed", searchParam],
      () => predictDataApi.getPredictSpeed(searchParam.linkId),
      {
        enabled: searchParam.linkId !== "",
        keepPreviousData: true,
      },
    );

  useEffect(() => {
    if (getPredictSpeedData) {
      console.log("✅ Sidebar getPredictSpeedData", getPredictSpeedData);
      let chartValues: ChartDataValue[] = [];

      getPredictSpeedData.forEach((item: any) => {
        item.items.forEach((i: any) => {
          let chartValue: ChartDataValue = {
            x: i.time,
            y: i.speed,
          };
          chartValues.push(chartValue);
        });
      });

      setChartData({
        name: searchParam.roadName,
        data: chartValues,
      });
    }
  }, [getPredictSpeedData, searchParam.roadName]);

  return (
    <>
      {searchParam.linkId === "" ? (
        <div className="w-full h-4/6 flex justify-center items-center">
          <p className="text-dark-grey font-bold">도로를 선택해주세요</p>
        </div>
      ) : getPredictSpeedLoading ? (
        <div className="w-full h-4/6 flex justify-center items-center">
          <p className="text-dark-grey font-bold">로딩 중</p>
        </div>
      ) : chartData.data?.length > 0 ? (
        <>
          <Line data={chartData} title={"현재 예측 속도"} />
        </>
      ) : (
        <div className="w-full h-4/6 flex justify-center items-center">
          <p className="text-dark-grey font-bold">해당하는 데이터가 없습니다</p>
        </div>
      )}
    </>
  );
};

export default PredictChartSection;
