import React, {
  useContext,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
} from "react";
import MapContext from "./MapContext";
import useEvent from "../hook/useEvent";

const Polyline = React.forwardRef(
  (
    {
      path,
      strokeWeight,
      strokeColor,
      strokeOpacity,
      strokeStyle,
      endArrow,
      onMouseover,
      onMouseout,
      onClick,
    }: any,
    ref: any,
  ) => {
    const map = useContext(MapContext);

    const polylineObj = useMemo(() => {
      return new kakao.maps.Polyline({
        path: path.map((coords: any) =>
          coords.map(
            (coord: any) => new kakao.maps.LatLng(coord.lat, coord.lng),
          ),
        ),
        strokeWeight,
        strokeColor,
        strokeOpacity,
        strokeStyle,
        endArrow,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => polylineObj, [polylineObj]);

    useLayoutEffect(() => {
      polylineObj.setMap(map);
      return () => polylineObj.setMap(null);
    }, [map, polylineObj]);

    useEvent(polylineObj, "click", onClick);
    useEvent(polylineObj, "mouseover", onMouseover);
    useEvent(polylineObj, "mouseout", onMouseout);

    return null;
  },
);

export default Polyline;
