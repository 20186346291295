import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ChartData, ChartDataValue } from "../../../../interface/chart";
import { useRecoilState, useRecoilValue } from "recoil";
import { clickState, refetchState, searchParamState } from "../../../../atoms";
import Line from "../../../chart/Line";
import { pastDataApi } from "../../../../api/pastDataApi";
import { predictDataApi } from "../../../../api/predictDataApi";
import { format } from "date-fns";

const PastPredictChartSection = () => {
  const searchParam = useRecoilValue(searchParamState);
  const [refetch, setRefetch] = useRecoilState(refetchState);
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [click, setClick] = useRecoilState(clickState);
  useEffect(() => {
    console.log("👻 chartData", chartData);
  }, [chartData]);
  useEffect(() => {
    setClick((prev) => ({
      ...prev,
      title: "과거 예측 속도",
      date: format(new Date(), "yyyy-MM-dd"),
      time: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { place, ...restSearchParams } = searchParam;

  /**
   * 과거 일평균 예측 속도 데이터 (기간별 평균) 쿼리
   */
  const {
    data: getPastPredictSpeedData,
    isLoading: getPastPredictSpeedLoading,
    isRefetching: getPastPredictSpeedRefetching,
  } = useQuery(
    ["getPastPredictSpeed", restSearchParams, refetch.predict],
    () => predictDataApi.getPastPredictSpeed(searchParam),
    {
      enabled:
        click.linkId !== "" && searchParam.startDate !== searchParam.endDate,
    },
  );

  /**
   * 과거 일평균 실측 속도 데이터 (기간별 평균) 쿼리
   */
  const {
    data: avgSpeedData,
    isLoading: avgSpeedDataLoading,
    isRefetching: avgSpeedDataRefetching,
  } = useQuery(
    ["getAvgSpeedData", restSearchParams, refetch.predict],
    () => pastDataApi.getAvgSpeedData(searchParam),
    {
      enabled:
        click.title === "과거 일평균 예측 속도" &&
        searchParam.linkId !== "" &&
        searchParam.startDate !== searchParam.endDate,
    },
  );
  /**
   * 과거 일평균 실측 및 예측 속도 데이터 (기간별 평균) 결과값 가공
   */
  useEffect(() => {
    if (avgSpeedData || getPastPredictSpeedData) {
      let pastChartValues: ChartDataValue[] = [];
      let predictCahrtValues: ChartDataValue[] = [];

      avgSpeedData &&
        avgSpeedData.forEach((item: any) => {
          let chartValue: ChartDataValue = {
            x: item.date,
            y: Math.round(item.avgSpeed * 100) / 100,
          };
          pastChartValues.push(chartValue);
        });

      getPastPredictSpeedData &&
        getPastPredictSpeedData.forEach((item: any) => {
          let chartValue: ChartDataValue = {
            x: item.date,
            y: Math.round(item.avgSpeed * 100) / 100,
          };
          predictCahrtValues.push(chartValue);
        });

      setChartData([
        {
          name: `${searchParam.roadName} 실측값`,
          data: pastChartValues,
        },
        {
          name: `${searchParam.roadName} 예측값`,
          data: predictCahrtValues,
        },
      ]);
      setClick((prev) => ({
        ...prev,
        title: "과거 일평균 예측 속도",
        isClickable: true,
        time: "",
      }));

      setRefetch((prev) => ({
        ...prev,
        predict: false,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPastPredictSpeedData, avgSpeedData, searchParam.roadName, setRefetch]);
  /**
   * 과거 예측 속도 데이터 (5분 단위 데이터) 쿼리
   */
  const {
    data: getPastPredictAllDaySpeedData,
    isLoading: getPastPredictAllDaySpeedLoading,
  } = useQuery(
    [
      "getPastPredictAllDaySpeed",
      searchParam.startDate === searchParam.endDate
        ? searchParam.linkId
        : click.date,
    ],
    () => predictDataApi.getPastPredictAllDaySpeed(click.linkId, click.date),
    {
      enabled:
        click.linkId !== "" && !!click.date && click.title === "과거 예측 속도",
    },
  );

  /**
   * 과거 일별 실측 속도 데이터 (5분 단위 데이터) 쿼리
   */
  const { data: allDaySpeedData, isLoading: allDaySpeedDataLoading } = useQuery(
    [
      "getAllDaySpeedData",
      // click.date,
      searchParam.startDate === searchParam.endDate
        ? searchParam.linkId
        : click.date,
    ],
    () => pastDataApi.getAllDaySpeedData(click.linkId, click.date),

    {
      enabled:
        click.linkId !== "" && !!click.date && click.title === "과거 예측 속도",
    },
  );
  /**
   * 과거 일별 실측 및 예측 속도 데이터 (5분 단위 데이터) 결과값 가공
   */
  useEffect(() => {
    if (allDaySpeedData || getPastPredictAllDaySpeedData) {
      console.log("✅ Sidebar allDaySpeedData", allDaySpeedData);
      let pastChartValues: ChartDataValue[] = [];
      let predictCahrtValues: ChartDataValue[] = [];

      allDaySpeedData &&
        allDaySpeedData.forEach((item: any) => {
          let chartValue: ChartDataValue = {
            x: item.time,
            y: item.speed,
          };
          pastChartValues.push(chartValue);
        });
      getPastPredictAllDaySpeedData &&
        getPastPredictAllDaySpeedData.forEach((item: any) => {
          let chartValue: ChartDataValue = {
            x: item.time,
            y: item.speed,
          };
          predictCahrtValues.push(chartValue);
        });
      setChartData([
        {
          name: `${searchParam.roadName} 실측값`,
          data: pastChartValues,
        },
        {
          name: `${searchParam.roadName} 예측값`,
          data: predictCahrtValues,
        },
      ]);
    }
  }, [
    getPastPredictAllDaySpeedData,
    allDaySpeedData,
    searchParam.roadName,
    click.linkId,
  ]);

  return (
    <>
      {searchParam.linkId === "" ? (
        <div className="w-full h-4/6 flex justify-center items-center">
          <p className="text-dark-grey font-bold">도로를 선택해주세요</p>
        </div>
      ) : getPastPredictSpeedLoading ||
        avgSpeedDataLoading ||
        getPastPredictAllDaySpeedLoading ||
        allDaySpeedDataLoading ||
        avgSpeedDataRefetching ||
        getPastPredictSpeedRefetching ? (
        <div className="w-full h-4/6 flex justify-center items-center">
          <p className="text-dark-grey font-bold">로딩 중</p>
        </div>
      ) : chartData?.length > 0 && chartData[0].data?.length > 0 ? (
        <>
          <Line data={chartData} title={"과거 예측 속도"} />
        </>
      ) : (
        <div className="w-full h-4/6 flex justify-center items-center">
          <p className="text-dark-grey font-bold">해당하는 데이터가 없습니다</p>
        </div>
      )}
    </>
  );
};

export default PastPredictChartSection;
