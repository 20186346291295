import React from "react";
import { useRecoilState } from "recoil";
import { mapParamState } from "../../atoms";

const SelectCity = () => {
  const [, setParam] = useRecoilState(mapParamState);
  const center: Record<string, any> = {
    seoul: { lat: 37.5665, lng: 126.978 }, // 서울특별시
    busan: { lat: 35.1796, lng: 129.0756 }, // 부산광역시
    daegu: { lat: 35.8722, lng: 128.6025 }, // 대구광역시
    incheon: { lat: 37.4563, lng: 126.7052 }, // 인천광역시
    gwangju: { lat: 35.1595, lng: 126.8526 }, // 광주광역시
    daejeon: { lat: 36.3504, lng: 127.3845 }, // 대전광역시
    ulsan: { lat: 35.5384, lng: 129.3114 }, // 울산광역시
    sejong: { lat: 36.4801, lng: 127.289 },
  };
  const handleSelectChange = (e: any) => {
    const cityKey = e.target.value;
    setParam((prev) => ({ ...prev, zoom: 8, center: center[cityKey] }));
  };

  return (
    <select className="cursor-pointer" onChange={(e) => handleSelectChange(e)}>
      <option value="seoul">서울특별시</option>
      <option value="busan">부산광역시</option>
      <option value="daegu">대구광역시</option>
      <option value="incheon">인천광역시</option>
      <option value="gwangju">광주광역시</option>
      <option value="daejeon">대전광역시</option>
      <option value="ulsan">울산광역시</option>
      <option value="sejong">세종특별자치시</option>
    </select>
  );
};

export default SelectCity;
