import React from "react";
import SearchForm from "../../search/SearchForm";
import PredictChartSection from "./section/PredictChartSection";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  clickState,
  polyLineClickState,
  refetchState,
  searchParamState,
} from "../../../atoms";
import { format, addHours } from "date-fns";
import PastPredictChartSection from "./section/PastPredictChartSection";

const PredictSpeedContent = () => {
  const polyLineClick = useRecoilValue(polyLineClickState);
  const searchParam = useRecoilValue(searchParamState);
  const sixHoursLater = addHours(new Date(), 6);
  const click = useRecoilValue(clickState);
  const formattedHours = format(sixHoursLater, "yyyy-MM-dd hh:mm aa");
  const isClicked = click.title === "과거 예측 속도" && click.date;
  const [, setRefetch] = useRecoilState(refetchState);
  const handleClickBack = () =>
    setRefetch((prev) => ({
      ...prev,
      predict: true,
    }));
  return (
    <>
      <div className="sidebar_box !h-64">
        <div className="px-3 flex justify-between mt-3">
          <p>
            <span className="sidebar_title">도로 이름</span>
            <span className="sidebar_title !text-bluesignal-blue ml-2">
              {polyLineClick.roadName}
            </span>
          </p>
          <span className="sidebar_title text-dark-grey">
            {polyLineClick.linkId === ""
              ? "도로를 선택해주세요"
              : polyLineClick.linkId}
          </span>
        </div>
        <SearchForm />
      </div>
      <div className="sidebar_box h-[300px]">
        <p className="flex justify-between">
          <span className="sidebar_title">
            {click.title}
            <span className="font-bold text-sm ml-2">
              {isClicked
                ? click.date
                : `${searchParam.startDate} ~ ${searchParam.endDate}`}
            </span>
          </span>
          {isClicked && (
            <button className="text-base" onClick={handleClickBack}>
              &larr;
            </button>
          )}
          {/*<span>*/}
          {/*  <span className="text-[#6bcbce] font-bold text-xs mr-2">*/}
          {/*    실제 데이터*/}
          {/*  </span>*/}
          {/*  <span className="text-[#4c5fef] font-bold text-xs">*/}
          {/*    예측 데이터*/}
          {/*  </span>*/}
          {/*</span>*/}
        </p>
        <PastPredictChartSection />
      </div>
      <div className="sidebar_box h-[350px]">
        <span className="sidebar_title">현재 예측 속도</span>
        <p className="flex justify-between items-center text-lg font-bold text-dark-grey">
          <span className="text-base font-medium text-sm">
            현재시각 기준 6시간 이후({formattedHours}) 까지 예측 가능
          </span>
        </p>
        <PredictChartSection />
      </div>
    </>
  );
};

export default PredictSpeedContent;
