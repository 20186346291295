import React from "react";

import { CellProps } from "../../interface/calendar";
import clsx from "clsx";

const Cell = ({
  onClick,
  children,
  className,
  isActive = false,
  disabled = false,
}: CellProps) => {
  return (
    <div
      onClick={!disabled && !isActive ? onClick : undefined} // disabled가 아니고 isActive가 아니면 클릭 이벤트 활성화
      className={clsx(
        "h-10 text-sm flex items-center justify-center transition-colors ", // 기본 css
        {
          // isActive가 아니고 disabled가 아니고 onClick 이면
          "cursor-pointer hover:bg-sky-100 active:bg-sky-200 rounded-lg":
            !disabled && !isActive && onClick,
          // isActive면
          "font-bold text-white bg-sky-500 rounded-lg": isActive,
          // disabled 이면
          "bg-gray-200": disabled,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Cell;
