import React, { useLayoutEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
const Infowindow = ({
  map,
  position,
  marker,
  children,
}: React.PropsWithChildren<any>) => {
  const container = useRef(document.createElement("div"));
  const infoWindow = useMemo(() => {
    const kakaoInfoWindow = new kakao.maps.InfoWindow({
      content: container.current,
      position: position,
    });
    container.current.style.display = "none";
    return kakaoInfoWindow;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    infoWindow.open(map, marker);
    return () => {
      infoWindow.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, marker]);

  return (
    container.current.parentElement &&
    ReactDOM.createPortal(children, container.current.parentElement)
  );
};

export default Infowindow;
