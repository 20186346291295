import React from "react";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  clickState,
  mapParamState,
  polyLineClickState,
  searchParamState,
} from "../../../atoms";
import { CustomPolylineProps } from "../../../interface/map";
import Polyline from "./Polyline";

const CustomPolyline = ({ item, index }: CustomPolylineProps) => {
  const param = useRecoilValue(mapParamState);
  const [searchParam, setSearchParam] = useRecoilState(searchParamState);
  const [, setClick] = useRecoilState(clickState);
  const [, setPolyLineClick] = useRecoilState(polyLineClickState);
  const getColorBySpeed = (state: string) => {
    if (state === "원활") {
      return "green";
    } else if (state === "서행") {
      return "orange";
    } else {
      return "red";
    }
  };

  const polyLinkClick = (item: any, mouseEvent: any) => {
    console.log("😶 polyLinkClick item", item);
    setSearchParam((prev: any) => ({
      ...prev,
      linkId: item.linkId,
      roadName: item.roadName,
    }));

    setClick((prev: any) => ({
      ...prev,
      linkId: item.linkId,
      date:
        searchParam.startDate === searchParam.endDate
          ? searchParam.startDate
          : "",
      time: "",
    }));

    setPolyLineClick({
      position: {
        lat: mouseEvent.latLng.getLat() + 0.00005,
        lng: mouseEvent.latLng.getLng(),
      },
      linkId: item.linkId,
      roadName: item.roadName,
      speed: item.speed,
      maxSpeed: item.maxSpeed,
    });
  };

  return (
    <Polyline
      key={item.linkId}
      path={[item.coordinates]}
      strokeWeight={3}
      strokeColor={getColorBySpeed(item.state)}
      strokeOpacity={0.6}
      strokeStyle={"solid"}
      endArrow={param.zoom < 4}
      onMouseover={(polyline: kakao.maps.Polyline) => {
        polyline.setOptions({ strokeWeight: 6 });
        polyline.setOptions({ strokeOpacity: 0.9 });
      }}
      onMouseout={(e: kakao.maps.Polyline) => {
        e.setOptions({ strokeWeight: 3 });
        e.setOptions({ strokeOpacity: 0.6 });
      }}
      // onClick={(polygon, mouseEvent: any) => polyLinkClick(item, mouseEvent)}
      onClick={(polygon: kakao.maps.Polygon, mouseEvent: any) =>
        polyLinkClick(item, mouseEvent)
      }
    />
  );
};

export default CustomPolyline;
