import React from "react";
import { useRecoilValue } from "recoil";
import { clickState } from "../../../../atoms";

const EventSection = ({ data }: any) => {
  const click = useRecoilValue(clickState);
  const evnetFormatter = (value: number) => {
    if (value === 0) {
      return "없음";
    } else if (value === 1) {
      return "공사 중";
    } else if (value === 2) {
      return "교통사고 발생";
    } else if (value === 3) {
      return "기상 악화";
    } else if (value === 4) {
      return "재난 상황";
    } else if (value === 5) {
      return "기타 돌발 상황";
    } else return "기타";
  };
  return (
    <div className="sidebar_box">
      <p className="sidebar_title">
        특이사항
        <span className="text-base font-medium ml-3">{click.time}</span>
      </p>
      <div className="flex justify-center mt-0">
        {!!data && !!click.time ? (
          <p>{evnetFormatter(data.event_type)}</p>
        ) : (
          <p className="text-dark-grey font-bold">시간을 선택해주세요</p>
        )}
      </div>
    </div>
  );
};

export default EventSection;
