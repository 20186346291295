import React from "react";
import clsx from "clsx";
import { ButtonProps } from "../../interface/Button";

const SearchBtn = ({ onClick, children, className, type }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={clsx(
        "flex justify-center items-center h-[50px] font-bold bg-bluesignal-pre-blue text-white w-full rounded-xl hover:bg-bluesignal-blue active:bg-bluesignal-after-blue transition-colors",
        className,
      )}
    >
      {children}
    </button>
  );
};

export default SearchBtn;
