import React from "react";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div className="wrapper">
      {/*<div className="header bg-neutral-100">*/}
      {/*  <a href="https://www.bluesignal.ai/">*/}
      {/*    <img*/}
      {/*      src={logo}*/}
      {/*      alt="logo"*/}
      {/*      className="w-[150px] h-[34px] cursor-pointer"*/}
      {/*    />*/}
      {/*  </a>*/}
      {/*  <span className="mx-auto mt-1 font-bold text-dark-grey">*/}
      {/*    속도 및 교통량 관측*/}
      {/*  </span>*/}
      {/*</div>*/}
      <div className="flex grow">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
