import { atom } from "recoil";
import { dateProps } from "./interface/search";
import { GetGeoProps, polyLinProps } from "./interface/map";
import { format } from "date-fns";
import { getDataProps } from "./interface/sidebar";
import { ClickProps, refetchProps } from "./interface/chart";
export const calendarState = atom<dateProps>({
  key: "calendarState",
  default: {
    minDate: String(new Date()),
    startDate: String(new Date()),
    endDate: String(new Date()),
  },
});

export const mapParamState = atom<GetGeoProps>({
  key: "mapParamState",
  default: {
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    zoom: 8,
    center: { lat: 37.56773520447912, lng: 126.98514921653536 },
    bounds: [
      {
        lat: 37.70429681223405,
        lng: 127.12355984882173,
      },
      {
        lat: 37.43177752475115,
        lng: 126.8295254225215,
      },
    ],
  },
});

export const searchParamState = atom<getDataProps>({
  key: "searchParamState",
  default: {
    linkId: "",
    roadName: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    place: "",
  },
});

export const clickState = atom<ClickProps>({
  key: "clickState",
  default: {
    title: "",
    isClickable: false,
    linkId: "",
    date: "",
    time: "",
  },
});

export const refetchState = atom<refetchProps>({
  key: "refetchState",
  default: {
    past: false,
    predict: false,
  },
});

export const polyLineClickState = atom<polyLinProps>({
  key: "polyLineClickState",
  default: {
    position: {
      lat: 0,
      lng: 0,
    },
    linkId: "",
    roadName: "",
    speed: 0,
    maxSpeed: 0,
  },
});
