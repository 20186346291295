import React, { useEffect } from "react";
import ApexChart from "react-apexcharts";
import { ChartProps } from "../../interface/chart";
import { addDays, format } from "date-fns";
import { clickState, searchParamState } from "../../atoms";
import { useRecoilState, useRecoilValue } from "recoil";

const LineChart = ({ data, selectedDate, title }: ChartProps) => {
  const searchParam = useRecoilValue(searchParamState);
  const [click, setClick] = useRecoilState(clickState);
  const nextDay = addDays(new Date(), 1);
  const isDataArray = Array.isArray(data);
  const seriesData = isDataArray ? data : [data];
  useEffect(() => {
    console.log("✨ click", click);
  }, [click]);

  const annotation = {
    xaxis: [
      {
        x: "00:00",
        // borderColor: "#00E396",
        label: {
          // borderColor: "#00E396",
          orientation: "horizontal",
          text: format(nextDay, "yyyy-MM-dd"),
        },
      },
    ],
  };
  return seriesData.length > 0 ? (
    <ApexChart
      type="line"
      series={seriesData}
      height={250}
      options={{
        chart: {
          id: "speedChart",
          events: {
            markerClick: function (
              event,
              chartContext,
              { seriesIndex, dataPointIndex, config },
            ) {
              if (
                data &&
                (click.title === "일평균속도" || click.title === "속도")
              ) {
                const clickedData = !isDataArray && data.data[dataPointIndex];

                if (clickedData && clickedData.x != null) {
                  setTimeout(() => {
                    setClick((prev) => ({
                      ...prev,
                      linkId: searchParam.linkId,
                      [click.title === "일평균속도" ? "date" : "time"]:
                        clickedData.x,
                    }));
                  }, 0);
                }
              } else if (data && click.title === "과거 일평균 예측 속도") {
                const clickedData = isDataArray && data[0].data[dataPointIndex];

                if (clickedData && clickedData.x != null) {
                  setTimeout(() => {
                    setClick((prev) => ({
                      ...prev,
                      title: "과거 예측 속도",
                      linkId: searchParam.linkId,
                      date: clickedData.x,
                    }));
                  }, 0);
                }
              }
            },
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoomin: true,
              zoomout: true,
              pan: false,
            },
            offsetX: -10,
            export: {
              csv: {
                filename: `${click.title}_${format(new Date(), "yyyyMMdd")}`,
                columnDelimiter: ",",
                headerCategory: selectedDate
                  ? `${selectedDate} 시간대별 속도`
                  : `일자`,
                headerValue: `${click.title} 속도`,
                dateFormatter(value) {
                  if (typeof value === "number") {
                    return new Date(value).toDateString();
                  }
                },
              },
              svg: {
                filename: click.title,
              },
              png: { filename: click.title },
            },
          },

          background: "transparent",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        theme: {
          mode: "light",
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
        },
        stroke: {
          width: 2,
          curve: "smooth",
          dashArray: [0, 5],
        },
        yaxis: {
          show: true,
        },
        annotations: title && title === "현재 예측 속도" ? annotation : {},
        xaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "0",
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        colors: ["#4c5fef", "#6bcbce"],
        tooltip: {
          y: {
            formatter: (value) => (!!value ? value + "km/h" : "데이터 없음"),
          },
          x: {
            show: false,
          },
        },
      }}
    />
  ) : (
    <></>
  );
};

export default React.memo(LineChart);
