import React, { useEffect, useMemo, useState } from "react";
import CustomPolyline from "./CustomPolyline";
import { useRecoilState, useRecoilValue } from "recoil";
import { mapParamState, polyLineClickState } from "../../../atoms";
import { useQuery } from "react-query";
import { mapApi } from "../../../api/mapApi";

const TrafficLine = () => {
  const [trafficLine, setTrafficLine] = useState<any>([]);
  const param = useRecoilValue(mapParamState);
  const [polyLineClick, setPolyLineClick] = useRecoilState(polyLineClickState);
  const polyLineInitial = useMemo(
    () => ({
      position: {
        lat: 0,
        lng: 0,
      },
      linkId: "",
      roadName: "",
      speed: 0,
      maxSpeed: 0,
    }),
    [],
  );
  const { data } = useQuery(["getGeo", param], () => mapApi.getKakaoGeo(param));

  useEffect(() => {
    // console.log("✅ main map data", data);
    if (data) {
      if (
        polyLineClick &&
        !data.some((item: any) => item.linkId === polyLineClick.linkId)
      ) {
        setPolyLineClick(polyLineInitial);
      }
      const polyLineArr = data.map((item: any, index: number) => (
        <CustomPolyline item={item} index={index} key={index} />
      ));
      setTrafficLine(polyLineArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return trafficLine;
};

export default TrafficLine;
