import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  calendarState,
  mapParamState,
  polyLineClickState,
  searchParamState,
} from "../../atoms";
import Popup from "./component/Popup";
import TrafficLine from "./component/TrafficLine";
import { format } from "date-fns";
import { Marker } from "../../interface/map";
import Map from "./component/Map";
import CustomOverlayMap from "./component/CustomOverlayMap";
import MapMarker from "./component/MapMarker";

const MainMap = () => {
  const [param, setParam] = useRecoilState(mapParamState);
  const searchParam = useRecoilValue(searchParamState);
  const date = useRecoilValue(calendarState);
  const polyLineClick = useRecoilValue(polyLineClickState);
  useEffect(() => {
    setParam((prev) => ({
      ...prev,
      startDate: format(new Date(date.startDate), "yyyy-MM-dd"),
      endDate: format(new Date(date.endDate), "yyyy-MM-dd"),
    }));
  }, [date.startDate, date.endDate, setParam]);

  const mapEvent = (map: kakao.maps.Map) => {
    setParam((prevParam: any) => ({
      ...prevParam,
      zoom: map.getLevel(),
      bounds: [
        {
          lat: map.getBounds().getNorthEast().getLat(),
          lng: map.getBounds().getNorthEast().getLng(),
        },
        {
          lat: map.getBounds().getSouthWest().getLat(),
          lng: map.getBounds().getSouthWest().getLng(),
        },
      ],
    }));
  };
  const [map, setMap] = useState<kakao.maps.Map | null>(null);
  const [info, setInfo] = useState<Marker>();
  const [markers, setMarkers] = useState<Marker[]>([]);
  useEffect(() => {
    if (!map || !searchParam.place) return;
    const ps = new kakao.maps.services.Places();

    // 마커 데이터를 재귀적으로 가져오는 함수
    const fetchAllMarkers = (
      keyword: string,
      nextPage = 1,
      accumulatedMarkers: Marker[] = [],
    ) => {
      ps.keywordSearch(
        keyword,
        (data, status, pagination) => {
          if (status === kakao.maps.services.Status.OK) {
            // console.log("data", data);
            const newMarkers = data.map((item) => ({
              position: {
                lat: parseFloat(item.y),
                lng: parseFloat(item.x),
              },
              content: item.place_name,
            }));

            const allMarkers = [...accumulatedMarkers, ...newMarkers];
            if (pagination.hasNextPage) {
              // 다음 페이지가 있다면 함수를 다시 호출
              fetchAllMarkers(keyword, pagination.current + 1, allMarkers);
            } else {
              // 마지막 페이지라면 상태를 업데이트
              setMarkers(allMarkers);
              const bounds = new kakao.maps.LatLngBounds();
              allMarkers.forEach((marker) =>
                bounds.extend(
                  new kakao.maps.LatLng(
                    marker.position.lat,
                    marker.position.lng,
                  ),
                ),
              );
              map.setBounds(bounds);
            }
          }
          // console.log("data", pagination);
        },
        { page: nextPage },
      );
    };

    // 검색 시작
    fetchAllMarkers(searchParam.place);
  }, [map, searchParam.place]);

  // useEffect(() => SearchPlace(map, setMarkers()), [map, searchParam.place]);

  const handleMarkerClick = (marker: any) => {
    map?.setCenter(
      new kakao.maps.LatLng(marker.position.lat, marker.position.lng),
    );
    setTimeout(() => {
      map?.setLevel(3, {
        anchor: new kakao.maps.LatLng(marker.position.lat, marker.position.lng),
        animate: {
          duration: 300,
        },
      });
    }, 500);
  };

  return (
    <Map
      center={param.center}
      level={param.zoom}
      className="w-screen grow"
      onZoomChanged={(map: any) => mapEvent(map)}
      onDragEnd={(map: any) => mapEvent(map)}
      maxLevel={13}
      onCreate={setMap}
    >
      <TrafficLine />
      {polyLineClick && (
        <CustomOverlayMap position={polyLineClick.position}>
          <Popup />
        </CustomOverlayMap>
      )}
      {markers.map((marker, index) => (
        <MapMarker
          key={`MapMarker(${index})-${marker.position.lat}:${marker.position.lng}`}
          position={marker.position}
          onMouseOver={() => setInfo(marker)}
          onClick={() => handleMarkerClick(marker)}
        >
          {
            // info && info.content === marker.content && marker.content
            info && info.content === marker.content && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: "10px",
                  maxWidth: "500px",
                  height: "60px",
                  color: "#000",
                  whiteSpace: "nowrap",
                }}
              >
                <p>{marker.content}</p>
              </div>
            )
          }
        </MapMarker>
      ))}
    </Map>
  );
};

export default MainMap;
