import React, { useState } from "react";
import clsx from "clsx";
import PastSpeedContent from "./pastSpeed/PastSpeedContent";
import PredictSpeedContent from "./predictSpeed/PredictSpeedContent";
import logo from "../../asset/img_logo.png";
import SelectCity from "../select/SelectCity";

const Sidebar = () => {
  const [selectedTab, setSelectedTab] = useState("pastSpeed");

  return (
    <>
      <div className="sidebar_container">
        <div className="flex justify-between pt-2 px-5 bg-white rounded-lg h-14">
          <a href="https://www.bluesignal.ai/">
            <img
              src={logo}
              alt="logo"
              className="w-[150px] h-[34px] cursor-pointer"
            />
          </a>
          <SelectCity />
        </div>
        <div
          className={clsx("sidebar_tab transition-colors shadow-2xl", {
            "bg-neutral-100": selectedTab === "pastSpeed",
            "bg-neutral-300": selectedTab !== "pastSpeed",
          })}
          onClick={() => setSelectedTab("pastSpeed")}
        >
          과거속도
        </div>
        <div
          className={clsx("sidebar_tab top-52 transition-colors shadow-2xl", {
            "bg-neutral-100": selectedTab === "predictSpeed",
            "bg-neutral-300": selectedTab !== "predictSpeed",
          })}
          onClick={() => setSelectedTab("predictSpeed")}
        >
          속도예측
        </div>

        {selectedTab === "pastSpeed" ? (
          <PastSpeedContent />
        ) : (
          <PredictSpeedContent />
        )}
      </div>
    </>
  );
};

export default Sidebar;
