import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Layout from "../page/Layout";
import Main from "../page/Main";
const Router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [{ index: true, element: <Main /> }],
  },
]);

export default Router;
