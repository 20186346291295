import React, {
  useContext,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import MapContext from "./MapContext";
import ReactDOM from "react-dom";

const CustomOverlayMap = React.forwardRef(
  ({ position, children }: any, ref: any) => {
    const overlayPosition = useMemo(() => {
      return new kakao.maps.LatLng(position.lat, position.lng);
    }, [position.lat, position.lng]);

    const container = useRef(document.createElement("div"));
    const map = useContext(MapContext);

    const overlay = useMemo(() => {
      const CustomOverlay = new kakao.maps.CustomOverlay({
        position: overlayPosition,
        content: container.current,
      });
      container.current.style.display = "none";

      return CustomOverlay;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position, children]);

    useLayoutEffect(() => {
      if (!map) return;
      overlay.setMap(map);
      return () => {
        overlay.setMap(null);
      };
    }, [map, overlay]);

    useImperativeHandle(ref, () => overlay, [overlay]);

    return (
      container.current.parentElement &&
      ReactDOM.createPortal(children, container.current.parentElement)
    );
  },
);

export default CustomOverlayMap;
