import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { dateProps } from "../../interface/search";
import Calendar from "../../util/calendar/Calendar";
import SearchBtn from "../button/SearchBtn";
import { useRecoilState, useRecoilValue } from "recoil";
import { calendarState, mapParamState, searchParamState } from "../../atoms";
import { format } from "date-fns";

const SearchForm = () => {
  const { handleSubmit, control, register } = useForm<dateProps>();
  const date = useRecoilValue(calendarState);
  const [, setParam] = useRecoilState(mapParamState);
  const [, setSearchParam] = useRecoilState(searchParamState);

  useEffect(() => {
    setSearchParam((prev) => ({
      ...prev,
      place: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setParam((prev) => ({
      ...prev,
      startDate: format(new Date(date.startDate), "yyyy-MM-dd"),
      endDate: format(new Date(date.endDate), "yyyy-MM-dd"),
    }));
    setSearchParam((prev) => ({
      ...prev,
      startDate: format(new Date(date.startDate), "yyyy-MM-dd"),
      endDate: format(new Date(date.endDate), "yyyy-MM-dd"),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setParam, setSearchParam, date.startDate, date.endDate]);

  const onValid = (data: dateProps) => {
    setParam((prev) => ({
      ...prev,
      startDate: format(new Date(date.startDate), "yyyy-MM-dd"),
      endDate: format(new Date(date.endDate), "yyyy-MM-dd"),
    }));
    setSearchParam((prev) => ({
      ...prev,
      startDate: format(new Date(date.startDate), "yyyy-MM-dd"),
      endDate: format(new Date(data.endDate), "yyyy-MM-dd"),
      place: data.place,
    }));
  };
  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onValid)}>
      <div className="pb-3">
        <input
          className="w-full pl-[20px] pr-[16px] textfield border border-silver rounded-lg h-[50px] appearance-none focus:outline-none cursor-pointer"
          placeholder="장소를 입력해주세요"
          {...register("place")}
        ></input>
      </div>
      <div className="calendar_wrapper relative">
        <Controller
          name="startDate"
          control={control}
          render={({ field }) => (
            <Calendar field={field} isStart={true} inputLen={"w-[150px]"} />
          )}
        />
        <hr className="w-[10px] border-silver" />
        <Controller
          name="endDate"
          control={control}
          render={({ field }) => (
            <Calendar field={field} inputLen={"w-[150px]"} />
          )}
        />
      </div>
      <SearchBtn type="submit">검색</SearchBtn>
    </form>
  );
};

export default React.memo(SearchForm);
