import axios, { CancelTokenSource } from "axios";
import pako from "pako";
import { GetGeoProps } from "../interface/map";
// 취소 토큰을 관리할 변수
let cancelTokenSource: CancelTokenSource | null = null;
export const mapApi = {
  getGeo: async (param: GetGeoProps) => {
    // console.log("✅ mapApi getGeo param", param);
    const { bounds, zoom } = param;
    // console.time("axios 요청");
    const response = await axios.get("traffic/links", {
      params: {
        minX: bounds[0]?.lng,
        minY: bounds[0]?.lat,
        maxX: bounds[1]?.lng,
        maxY: bounds[1]?.lat,
        zoomLevel: zoom,
      },
      responseType: "arraybuffer",
    });
    // console.timeEnd("axios 요청");
    // console.time("압축 해제");
    // console.log("response.data.features", response);
    const decompressedData = pako.ungzip(new Uint8Array(response.data), {
      to: "string",
    });

    // console.log("decompressedData", decompressedData);
    const jsonData = JSON.parse(decompressedData);
    // console.timeEnd("압축 해제");
    return jsonData;
  },
  getKakaoGeo: async (param: GetGeoProps) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Previous request cancelled.");
    }
    console.log("✅ mapApi getGeo param", param);
    const { bounds, zoom } = param;
    cancelTokenSource = axios.CancelToken.source();
    try {
      const response = await axios.get("traffic/links/coord", {
        params: {
          minX: bounds[0]?.lng,
          minY: bounds[0]?.lat,
          maxX: bounds[1]?.lng,
          maxY: bounds[1]?.lat,
          zoomLevel: zoom,
        },
        cancelToken: cancelTokenSource.token, // 취소 토큰을 요청에 추가
        responseType: "arraybuffer",
      });

      // console.log("response.data.features", response);
      const decompressedData = pako.ungzip(new Uint8Array(response.data), {
        to: "string",
      });

      // console.log("decompressedData", decompressedData);
      const jsonData = JSON.parse(decompressedData);

      // console.log("jsonData", jsonData);
      return jsonData;
    } catch (error) {
      if (!axios.isCancel(error)) {
      }
    }
  },
};
