import React from "react";
import { useRecoilValue } from "recoil";
import { clickState } from "../../../../atoms";

const WeatherSection = ({ data }: any) => {
  const click = useRecoilValue(clickState);
  const weatherFormatter = (value: number) => {
    if (value === 0) {
      return "맑음";
    } else if (value === 1) {
      return "비";
    } else if (value === 2) {
      return "비/눈 ";
    } else if (value === 3) {
      return "눈";
    } else if (value === 4) {
      return "소나기";
    }
  };
  return (
    <div className="sidebar_box">
      <p className="text-lg font-bold text-dark-grey">
        날씨 <span className="text-base font-medium ml-3">{click.time}</span>
      </p>
      {!!data && !!click.time ? (
        <table className="w-full">
          <tbody>
            <tr className="border border-silver">
              <td className="border border-silver w-1/3">강수 형태</td>
              <td>{data && weatherFormatter(data.pty)}</td>
            </tr>
            <tr className="border border-silver">
              <td className="border border-silver w-1/3">습도</td>
              <td>{`${data && data.reh}%`}</td>
            </tr>
            <tr className="border border-silver">
              <td className="border border-silver w-1/3">기온</td>
              <td>{`${Math.round(data && data.t1h * 100) / 100}℃`}</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <p className="text-dark-grey font-bold text-center">
          시간을 선택해주세요
        </p>
      )}
    </div>
  );
};

export default WeatherSection;
