import React from "react";

const Legend = ({ maxSpeed }: { maxSpeed: number }) => {
  let standard = {
    fast: 0,
    slow: 0,
  };
  if (maxSpeed > 10 && maxSpeed <= 30) {
    standard.slow = 10;
    standard.fast = 20;
  } else if (maxSpeed === 40) {
    standard.slow = 15;
    standard.fast = 25;
  } else if (maxSpeed === 50) {
    standard.slow = 15;
    standard.fast = 30;
  } else if (maxSpeed === 60) {
    standard.slow = 20;
    standard.fast = 35;
  } else if (maxSpeed === 70) {
    standard.slow = 25;
    standard.fast = 40;
  } else if (maxSpeed === 80) {
    standard.slow = 25;
    standard.fast = 45;
  } else if (maxSpeed >= 90) {
    standard.slow = 30;
    standard.fast = 60;
  }

  return (
    <div className="sidebar_box">
      <p className="flex justify-between text-lg font-bold text-dark-grey">
        <span>범례</span>
        <span className="text-base">속도 제한 {maxSpeed} 도로</span>
      </p>
      <div className="flex justify-center mt-0 space-x-5">
        <div className="flex items-center space-x-1">
          <span className="font-bold">{standard.slow} 이하</span>
          <div className="w-4 h-4 bg-[#FF0000] rounded-full"></div>
          <span className="font-bold">지체</span>
        </div>
        <div className="flex items-center space-x-1">
          <span className="font-bold">
            {standard.slow} 초과 {standard.fast} 이하
          </span>
          <div className="w-4 h-4 bg-[#FFA501] rounded-full"></div>
          <span className="font-bold">서행</span>
        </div>
        <div className="flex items-center space-x-1">
          <span className="font-bold">{standard.fast} 초과</span>
          <div className="w-4 h-4 bg-[#018001] rounded-full"></div>
          <span className="font-bold">원활</span>
        </div>
      </div>
    </div>
  );
};

export default Legend;
