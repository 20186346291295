import { useLayoutEffect } from "react";

const UseEvent = (target: any, type: any, callback: any) => {
  useLayoutEffect(() => {
    const eventHandler = (event: any) => {
      if (callback) {
        callback(target, event);
      }
    };
    kakao.maps.event.addListener(target, type, eventHandler);

    return () => {
      kakao.maps.event.removeListener(target, type, eventHandler);
    };
  }, [target, type, callback]);
};

export default UseEvent;
