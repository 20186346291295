import axios from "axios";
import { getDataProps } from "../interface/sidebar";

export const pastDataApi = {
  getAvgSpeedData: async (param: getDataProps) => {
    // console.log("✅ dataApi getSpeedData param", param);
    const { linkId, startDate, endDate } = param;
    const response = await axios.get(`traffic/link/avg/speed/${linkId}`, {
      params: {
        startDate,
        endDate,
      },
    });
    console.log("✅ dataApi getSpeedData data", response.data.data);
    return response.data?.data?.items;
  },

  getAllDaySpeedData: async (linkId: string, date: string) => {
    const response = await axios.get(`traffic/link/speed/${linkId}/${date}`);
    console.log("✅ dataApi getAllDaySpeedData data", response.data.data);
    return response.data?.data?.items;
  },

  getTrafficInfo: async (param: getDataProps) => {
    // console.log("✅ dataApi getTrafficInfo param", param);
    const { linkId, date, time } = param;
    const response = await axios.get(
      `traffic/link/info/${linkId}/${date}/${time}`,
    );
    // console.log("✅ dataApi getTrafficInfo data", response.data.data);
    return response.data?.data;
  },
};
