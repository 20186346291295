import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ChartData, ChartDataValue } from "../../../../interface/chart";
import { useRecoilState, useRecoilValue } from "recoil";
import { clickState, refetchState, searchParamState } from "../../../../atoms";
import Line from "../../../chart/Line";
import { pastDataApi } from "../../../../api/pastDataApi";
import { format } from "date-fns";

const ChartSection = () => {
  const searchParam = useRecoilValue(searchParamState);
  const [refetch, setRefetch] = useRecoilState(refetchState);
  const [chartData, setChartData] = useState<ChartData>({
    name: searchParam.roadName,
    data: [],
  });
  const [click, setClick] = useRecoilState(clickState);
  const { place, ...restSearchParams } = searchParam;

  useEffect(
    () =>
      console.log(
        "✅ searchParam",
        searchParam,
        searchParam.startDate === searchParam.endDate,
      ),
    [searchParam],
  );

  useEffect(() => {
    setClick((prev) => ({
      ...prev,
      title: "속도",
      date: format(new Date(), "yyyy-MM-dd"),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * 일평균속도 데이터 (기간별) 쿼리
   */
  const { data: avgSpeedData, isLoading: avgSpeedDataLoading } = useQuery(
    ["getAvgSpeedData", restSearchParams, refetch.past],
    () => pastDataApi.getAvgSpeedData(searchParam),
    {
      enabled:
        searchParam.linkId !== "" &&
        searchParam.startDate !== searchParam.endDate,
      keepPreviousData: true,
    },
  );
  /**
   * 일평균속도 데이터 (기간별) 결과값 가공
   */
  useEffect(() => {
    if (avgSpeedData) {
      // console.log("✅ Sidebar avgSpeedData", avgSpeedData);
      let chartValues: ChartDataValue[] = [];

      avgSpeedData.forEach((item: any) => {
        let chartValue: ChartDataValue = {
          x: item.date,
          y: Math.round(item.avgSpeed * 100) / 100,
        };
        chartValues.push(chartValue);
      });
      setChartData({
        name: searchParam.roadName,
        data: chartValues,
      });
      setClick((prev) => ({
        ...prev,
        title: "일평균속도",
        isClickable: true,
        linkId: "",
        time: "",
      }));
      setRefetch((prev) => ({
        ...prev,
        past: false,
      }));
    }
  }, [searchParam.roadName, setClick, setRefetch, avgSpeedData]);

  /**
   * 속도 데이터 (5분 단위) 쿼리
   */
  const { data: allDaySpeedData, isLoading: allDaySpeedDataLoading } = useQuery(
    [
      "getAllDaySpeedData",
      // click.date,
      searchParam.startDate === searchParam.endDate
        ? searchParam.linkId
        : click.date,
    ],
    () => pastDataApi.getAllDaySpeedData(searchParam.linkId, click.date),

    {
      enabled: click.linkId !== "" && !!click.date,
      keepPreviousData: true,
    },
  );
  /**
   * 속도 데이터 (5분 단위) 결과값 가공
   */
  useEffect(() => {
    if (allDaySpeedData) {
      console.log("✅ Sidebar allDaySpeedData", allDaySpeedData);
      let chartValues: ChartDataValue[] = [];

      allDaySpeedData.forEach((item: any) => {
        let chartValue: ChartDataValue = {
          x: item.time,
          y: item.speed,
        };
        chartValues.push(chartValue);
      });
      setChartData({
        name: searchParam.roadName,
        data: chartValues,
      });

      setClick((prev) => ({
        ...prev,
        title: "속도",
      }));
    }
  }, [searchParam.roadName, setClick, setRefetch, allDaySpeedData]);

  return (
    <>
      {searchParam.linkId === "" ? (
        <div className="w-full h-4/6 flex justify-center items-center">
          <p className="text-dark-grey font-bold">도로를 선택해주세요</p>
        </div>
      ) : allDaySpeedDataLoading || avgSpeedDataLoading ? (
        <div className="w-full h-4/6 flex justify-center items-center">
          <p className="text-dark-grey font-bold">로딩 중</p>
        </div>
      ) : chartData.data?.length > 0 ? (
        <>
          <Line
            data={chartData}
            setClick={setClick}
            selectedDate={click.date}
            title={click.title}
          />
        </>
      ) : (
        <div className="w-full h-4/6 flex justify-center items-center">
          <p className="text-dark-grey font-bold">해당하는 데이터가 없습니다</p>
        </div>
      )}
    </>
  );
};

export default ChartSection;
