import React from "react";
import { useRecoilState } from "recoil";
import { polyLineClickState } from "../../../atoms";

const Popup = () => {
  const [polyLineClick, setPolyLineClick] = useRecoilState(polyLineClickState);
  const polyLineInitial = {
    position: {
      lat: 0,
      lng: 0,
    },
    linkId: "",
    roadName: "",
    speed: 0,
    maxSpeed: 0,
  };
  return (
    <div className="absolute -top-32 -left-32 px-3 py-1 bg-white border-0 w-[280px] h-32 rounded-lg shadow-xl">
      <div className="flex">
        <div className="grow flex flex-col justify-center space-y-2 pt-5 px-2">
          <p>
            도로 아이디 :
            <span className="ml-2 font-bold">{polyLineClick.linkId}</span>
          </p>
          <p>
            도로이름 :
            <span className="ml-2 font-bold">{polyLineClick.roadName}</span>
          </p>
          <p>
            평균 속도 :
            <span className="ml-2 font-bold">{`${polyLineClick.speed} km/h`}</span>
          </p>
        </div>
        <button
          className="self-start ml-3"
          onClick={() => setPolyLineClick(polyLineInitial)}
        >
          x
        </button>
      </div>
      {/* 팝업창 방향 표시 */}
      <div className="absolute z-10 border-white border-r-transparent border-l-transparent border-b-transparent border-[10px] -bottom-5 left-[117px]"></div>
    </div>
  );
};

export default Popup;
