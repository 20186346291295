import React from "react";
import Sidebar from "../component/sidebar/Sidebar";
import MainMap from "../component/map/MainMap";

const Main = () => {
  return (
    <>
      <Sidebar />
      {/*<LeafletMap />*/}
      {/*<KakaoMap />*/}
      <MainMap />
    </>
  );
};

export default Main;
