import React from "react";
import SearchForm from "../../search/SearchForm";
import ChartSection from "./section/ChartSection";
import EventSection from "./section/EventSection";
import WeatherSection from "./section/WeatherSection";
import Legend from "./section/Legend";
import { useQuery } from "react-query";
import { pastDataApi } from "../../../api/pastDataApi";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  clickState,
  polyLineClickState,
  refetchState,
  searchParamState,
} from "../../../atoms";

const PastSpeedContent = () => {
  const searchParam = useRecoilValue(searchParamState);
  const click = useRecoilValue(clickState);
  const [, setRefetch] = useRecoilState(refetchState);
  const polyLineClick = useRecoilValue(polyLineClickState);
  const isClicked = click.title === "속도" && click.date;

  const { data, isLoading } = useQuery(
    ["getTrafficInfo", click],
    () => pastDataApi.getTrafficInfo(click),
    { enabled: !!click.time && click.title === "속도", keepPreviousData: true },
  );

  const handleClickBack = () =>
    setRefetch((prev) => ({
      ...prev,
      past: true,
    }));
  return (
    <>
      <div className="sidebar_box !h-64">
        <div className="px-3 flex justify-between mt-3">
          <p>
            <span className="sidebar_title">도로 이름</span>
            <span className="sidebar_title !text-bluesignal-blue ml-2">
              {polyLineClick.roadName}
            </span>
          </p>
          <span className="sidebar_title text-dark-grey">
            {polyLineClick.linkId === ""
              ? "도로를 선택해주세요"
              : polyLineClick.linkId}
          </span>
        </div>
        <SearchForm />
      </div>
      <div className="sidebar_box h-1/3">
        <p className="flex justify-between sidebar_title">
          <span>
            <span>{click.title}</span>
            <span className="ml-3 text-base font-medium">
              {isClicked
                ? click.date
                : `${searchParam.startDate} ~ ${searchParam.endDate}`}
            </span>
          </span>
          {isClicked && (
            <button className="text-base" onClick={handleClickBack}>
              &larr;
            </button>
          )}
        </p>
        <ChartSection />
      </div>
      {click.title === "속도" ? (
        <>
          <EventSection data={!isLoading && data && data} />
          <WeatherSection data={!isLoading && data && data} />
        </>
      ) : (
        <></>
      )}
      {isClicked ? (
        <></>
      ) : (
        polyLineClick.maxSpeed !== 0 && (
          <Legend maxSpeed={polyLineClick.maxSpeed} />
        )
      )}
    </>
  );
};

export default PastSpeedContent;
